@import url("./fonts/stylesheet.css");

.MuiMenuItem-root {
  padding: 0;
}
.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop,
.css-919eu4 {
  background-color: #f1f3f5 !important;
}
table.countryTable {
  border: 1px solid black;
  border-spacing: 0px;
}
table.countryTable tr:not(:last-child) td,
table.countryTable tr:first-child th {
  border-bottom: 1px solid black;
}
table.countryTable tr td:not(:last-child) {
  border-right: 1px solid black;
}

table.countryTable tr td,
table.countryTable tr th {
  padding: 10px;
}
